<template>
  <ag-table-client
    v-if="items.length"
    :column-defs="columnDefs"
    :get-row-id="getRowId"
    :is-row-selectable="isRowSelectable"
    :page-size="1000"
    :row-data="items"
    :selected-items="selectedItems"
    :sort="{ colId: 'createdAt', sort: 'desc' }"
    table-id="MarketplaceOrdersTable"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { ClientConfig } from '@/utils/helpers/rest';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ColDef, RowNode } from 'ag-grid-enterprise';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace';
import { OmsOrder, OmsOrdersResponse } from '@/modules/marketplace/models';

const allActions = ['view', 'edit', 'route', 'unroute', 'cancel'];

const counterpartyChoiceEnabledCols = ['counterparties'];
const settlementTypeChoiceEnabledCols = ['settlementType'];

@Component({
  components: {
    AgTableClient,
  },
  props: {
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    actions: {
      type: Array as PropType<string[]>,
      default: () => allActions,
    },
    items: {
      type: Array as PropType<OmsOrdersResponse['data']>,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<OmsOrdersResponse['data']>,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class MarketplaceOrdersTable extends Vue {
  // props
  protected readonly omitHeaders!: string[];
  protected readonly actions!: string[];
  protected readonly items!: OmsOrdersResponse['data'];
  protected readonly selectedItems!: OmsOrder[];
  protected readonly showSelect!: boolean;

  // store state
  protected clientConfig!: ClientConfig;

  // subset of items currently rendered in the table
  protected currentRenderedItems: OmsOrdersResponse['data'] = [];

  protected get columnDefs(): ColDef[] {
    return [
      cols.checkbox(),
      cols.status({
        viewOrder: this.viewOrder,
      }),
      cols.side(),
      cols.active(),
      cols.company(),
      cols.ticker(),
      cols.cusip(),
      cols.openQuantity(),
      cols.rate(),
      cols.execQty(),
      cols.avgExecRate(),
      cols.totalQuantity(),
      cols.createTime(),
      cols.orderType(),
      cols.timeInForce(),
      cols.minQuantity(),
      cols.counterparties(),
      cols.settlementType(),
      cols.orderRef(),
      cols.actions({
        actions: this.actions,
        viewOrder: this.viewOrder,
        editOrder: (order: OmsOrder) => this.$emit('edit-order', order),
      }),
    ].filter(
      (colDef) =>
        (this.showSelect || colDef.colId !== 'checkbox') &&
        !this.omitHeaders.includes(colDef.field as string) &&
        (this.clientConfig.orderbookCounterpartyChoiceEnabled ||
          !counterpartyChoiceEnabledCols.includes(colDef.field as string)) &&
        (this.clientConfig.orderbookSettlementTypeChoiceEnabled ||
          !settlementTypeChoiceEnabledCols.includes(colDef.field as string))
    );
  }

  public viewOrder(orderRef: string): void {
    this.$emit('view-order', orderRef);
  }

  protected getRowId(order: OmsOrder): string {
    return order.orderRef;
  }

  protected isRowSelectable(rowNode: RowNode): boolean {
    return !this.isStatusDisabled(rowNode.data.status);
  }

  // if the order status is one of the fixed array items return true
  // in the template the checkbox :disabled will be true
  protected isStatusDisabled(status: OmsOrder['status']): boolean {
    const disabledStatuses: Array<OmsOrder['status']> = [
      'CLOSED',
      'CANCELED',
      'FILLED',
      'EXPIRED',
      'TERMINATED',
    ];

    return disabledStatuses.includes(status);
  }

  protected filterOpenItems(items: OmsOrdersResponse['data']): OmsOrdersResponse['data'] {
    return items.filter((item) => item.status !== 'FILLED' && item.status !== 'CANCELED');
  }
}
</script>
